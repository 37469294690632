.problemInfo {
    width: 80vw;
    text-align: left;
}

.problemInfo p {
    font-size: max(1.15vw, 1.8vh);
}

.problemInfo .note {
    margin-top: 5vh !important;
    font-weight: bold;
}

.problemInfo img {
    width: 40vw;

    margin: 5vh 0vw;
}

.problemInfo .desc {
    display: flex;
    flex-direction: row;

    max-width: 70vw;
}

.problemInfo .desc p {
    max-width: 70vw;

    margin: 2vh 0vw;
    
    word-wrap: break-word;
}